<template>
  <div>
    <template v-for="index in 3" :key="index">
      <button @click="genPush(index-1)" style="margin-bottom: 10px;">Push {{ index }}</button>
    </template>
  </div>
</template>

<script>

import {notificationList, NOTIFY_TYPES} from '@store/test/testNotification';

const user = {
  firstName: 'John',
  id: 'a9a1cce3-8170-4370-abeb-8b077a9ae3e5',
  lastName: 'Rex',
  mail: 'rexty@dev.chatmail.rtelekom.sparklingtide.dev',
  profilePhoto: null
};

const notify = {
  id: 1005,
  user: user,
  action: 'write a message:',
  type: NOTIFY_TYPES.COMMUNITY_CHAT_MESSAGE,
  title: 'I will check this out.',
  community: [
    { id: 1,  name: 'Frontend Team' }
  ],
  datetime: '2022-04-21T16:43:20',
  isRead: false
};

const notifyList = [
  {...notify, type: NOTIFY_TYPES.EMAIL_DELAY_SENDING_COMPLETE, action: 'email delay complete', title: 'Test letter 01'},
  {...notify, type: NOTIFY_TYPES.COMMUNITY_CREATE_BOARD, action: 'board created', title: 'Board for fun'},
  {...notify, type: NOTIFY_TYPES.PERSONAL_ADD_USER, action: 'used added', title: 'Serious Sam'},
];

export default {
  name: 'TestNotification',
  data() {
    return {
    };
  },
  methods: {
    genPush($event) {
      DIALOG.push(notifyList[$event]);
      notificationList.value.push(notifyList[$event]);
    }
  }
};

</script>

<style lang="scss" scoped>

.page-test {
  background-color: #fff;
}


</style>
